/**
 * JavaScript methods on scroll
 */

import $ from 'jquery'
import { backToTop } from './toTop'

$(window).scroll(function() {
	var scrollPos = $(window).scrollTop()

	if(scrollPos > 5) {
		$('header, #content').addClass('fixed')
	} else {
		$('header, #content').removeClass('fixed')
	}

	var opacityVal = (scrollPos / 400)
	$('.banner-main .banner-caption').css('opacity', 1 - opacityVal)

	backToTop(scrollPos)
})
