/**
 * Custom Theme Scripts Here
 */

import $ from 'jquery'
import './functions/onScroll'
import { inlineScroll, parallax } from './functions'
//import { googleMap } from './components'
import './components/contact';
import { isTouch } from './variables'

$(document).ready(function() {
	$('a[title="Contact Us"], a.inline-link').on('click', function(e) {
		inlineScroll($(this).attr('href'))
		e.preventDefault()

	})
	$('a.apply').on('click', function(e) {
		inlineScroll($(this).attr('href'))
		e.preventDefault()
		$('#subject>option:eq(4)').prop('selected', true)
		$('#subject').trigger('change');
	})
	//googleMap()

	$('#testimonialCarousel').carousel()

	if(!isTouch) {
		parallax($('.jumbotron'), '50%', .5)
	}

	var $msContainers = jQuery('.ms-container');

	$msContainers.each(function() {
		var $msContainer = jQuery(this);

		$msContainer.imagesLoaded(function() {
			$msContainer.masonry({
				itemSelector: '.ms-item',
				percentPosition: true
			});
		});
	});


})
