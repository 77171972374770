import $ from 'jquery'

var subject = $('select#subject')
var subjectVal = subject.val()
var returnMsg = $('#rsp')

if(subjectVal === 'resume') {
	$('#resume-input').slideDown()
} else if(subjectVal === 'project') {
	$('#potential-project').slideDown()
}

subject.change(function() {
	subjectVal = $(this).val()

	if(subjectVal === 'resume') {
		$('#resume-input').slideDown()
	} else {
		$('#resume-input').slideUp()
	}

	if(subjectVal === 'rfp') {
		$('#rfp-input, #rfp-input2').slideDown()
	} else {
		$('#rfp-input, #rfp-input2').slideUp()
	}
	if(subjectVal === 'project') {
		$('#potential-project').slideDown()
	} else {
		$('#potential-project').slideUp()
	}

})

$('form#contact-form').submit(function(e) {
	e.preventDefault()

	var formData = new FormData($(this)[0])

	$.ajax({
		type: "POST",
		url: lexi.ajaxurl,
		dataType: 'json',
		data: formData,
		processData: false,
		contentType: false,
		async: false,
		beforeSend: function() {
				$('form#contact-form input[type="submit"]').val('Sending...');
		},
		complete: function() {
				$('form#contact-form input[type="submit"]').val('Message Sent!');
		},
		success: function(data) {
				returnMsg.show();
				returnMsg.html(data.message);

				if(data.status === 1) {
						$('form#contact-form input, textarea').val('');
						$('form#contact-form select[name="subject"]').val('Select a subject');
						$('form#contact-form select[name="time-frame"]').val('Immediately (1-3 months)');
						$('form#contact-form input[type="submit"]').val('Submit');
				}
		}
	})
})
