
const ajaxurl = lexi.ajaxurl
const isMobile = lexi.isMobile
const currentPage = lexi.curPage
const isSinglePost = lexi.isSingle
const ajaxnonce = lexi.ajaxnonce

export {
	ajaxurl,
	isMobile,
	currentPage,
	isSinglePost,
	ajaxnonce
}
